import React from 'react';
import Layout from '../components/Layout';
import { withIntl } from '../i18n';
import Landing from '../templates/Landing';

const host = process.env.GATSBY_HOST;

const code = {
  header: {
    title: 'codeHeaderTitle',
    subTitle: 'codeHeaderSubTitle',
    image: 'code.png',
    button1: {
      text: 'registration',
      subText: 'registrationSubText',
      link: `${host}/auth2/single_account?redirect_uri=${host}/&enter=signup`,
      event: 'CODE_get_started',
      id: 'sign_up_code'
    },
    button2: {
      text: 'orderDemo',
      link: '/form/live-demo',
      event: 'CODE_request_demo',
      id: 'request_demo_code'
    }
  },
  content: [
    {
      title: 'codeContentRepoTitle',
      subTitle: 'codeContentRepoSubTitle',
      image: 'code_1.svg',
    },
    {
      title: 'codeContentProcTitle',
      subTitle: 'codeContentProcSubTitle',
      image: 'code_2.svg',
    },
    {
      title: 'codeContentLangTitle',
      subTitle: 'codeContentLangSubTitle',
      image: 'code_3.svg',
    },
    {
      title: 'codeContentHostTitle',
      subTitle: 'codeContentHostSubTitle',
      image: 'code_4.svg',
    },
    {
      title: 'codeContentScaleTitle',
      subTitle: 'codeContentScaleSubTitle',
      image: 'code_5.svg',
    }
  ],
  demo: {
    event: 'CODE_digital_core_start'
  }
};


const Code = (props) => (
  <Layout {...props}>
    <Landing
      data={code}
      page={'code'}
    />
  </Layout>
);


export default withIntl(Code);

